<template>
  <div class="caseDetail">
    <PageTitle ttl-en="CASE" ttl-jp="_想定課題" />
    <div class="inner">
      <p class="caseDetail__mv js-scroll">
        <img src="/genbashiko/img/case/case01/mv.jpg" alt="" />
      </p>
      <section class="caseDetail__outline">
        <h1 class="js-scroll">
          塗装検査工程のライン照明の接続部に
          <br />切れ目が生じて不良を見逃しやすい
        </h1>
        <p class="caseDetail__outline__txt js-scroll">
          塗装検査ではゼブラ照明を用いて、光が当たっている部分とそうでない部分の境界線上で、キズや打痕などを発見するという手法が採られています。ところが、従来タイプの蛍光灯やLEDでゼブラ照明を組んだ場合、照明のつなぎ目の部分に光の切れ目が生じてしまい、不良を見落としてしまうケースがあります。照明の切れ目を避けて検査するために、そのために余分な動作が必要となり、時間のロスやコストアップにつながります。
        </p>
        <div class="caseDetail__outline__cate js-scroll">
          <p>担当領域:</p>
          <ul>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}"
              >
                既存の検査場を改修したい
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '検査現場を省人化したい' }}"
              >
                検査現場を省人化したい
              </router-link>
            </li>
            <li>
              <router-link class="category" :to="{ name: 'Case', query: { category: '塗装完成車工程' }}">
                塗装完成車工程
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=現場調査">
                現場調査
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=施策策定">
                施策策定
              </router-link>
            </li>
            <li>
              <router-link
                class="category"
                to="/case?category=コンサルティング"
              >
                コンサルティング
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=製品開発">
                製品開発
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=納品後サポート">
                納品後サポート
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <section class="caseDetail__env">
        <h3 class="caseDetail__env__ttl js-scroll">
          想定される環境
        </h3>
        <ul class="js-scroll">
          <li>蛍光灯のライン照明が上部に設置された塗装検査ライン</li>
        </ul>
      </section>
      <section class="caseDetail__ex">
        <h3 class="caseDetail__ex__ttl js-scroll">
          想定課題と具体的な改善施策例
        </h3>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題1
            </p>
            <p class="txt">
              光の切れ目が生じるために、不良を見逃しやすい
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策1</span>
            <p class="txt">
              つなぎ目のない照明を導入
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case01/image01.jpg" alt="" />
            </p>
            <p>
              <img src="/genbashiko/img/case/case01/image02.jpg" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            つなぎ目なしで連結が可能な「ルーチ・レクタ AAA
            スペクトC」を導入し、切れ目がないライン照明を実現。これによって見落としの防止につながっています。
          </p>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a href="/genbashiko/upload/download_pdf/PA-1-1.pdf" target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">
              課題2
            </p>
            <p class="txt">
              ゼブラ照明を組む予算が足りない
            </p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <p class="ttl">
              施策2
            </p>
            <p class="txt">
              可動型ゼブラ照明を製作する
            </p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case01/image03.jpg" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            他の現場でも利用できるようにキャスター付きの簡易型ゼブラ照明を製作することで、他の検査現場と兼用することを可能にし、低予算で導入することができます。
          </p>
        </div>
        <div class="caseDetail__ex__movie js-scroll">
          <div class="caseDetail__ex__movie__block">
            <p class="caseDetail__ex__movie__ttl">
              ▼【日本語版】商品ムービー
            </p>
            <div class="caseDetail__ex__movie__iframeWrap">
              <iframe
                src="https://www.youtube.com/embed/Ifl1AAtcZug"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="caseDetail__ex__movie__block">
            <p class="caseDetail__ex__movie__ttl">
              ▼【海外版】商品ムービー
            </p>
            <div class="caseDetail__ex__movie__iframeWrap">
              <iframe
                src="https://www.youtube.com/embed/t_jt-j0T60U"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a href="/genbashiko/upload/download_pdf/PA-1-1.pdf" target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue'

export default {
  components: {
    PageTitle
  },
  head: {
    title: {
      inner: 'Case01'
    },
    meta: [
      { property: 'og:title', content: 'Case01｜GENBA SHIKO' },
      {
        name: 'description',
        content:
          '塗装検査工程のライン照明の接続部に切れ目が生じて不良を見逃しやすいなら、つなぎ目のない照明器具の導入で車体品質の確保をサポートします。'
      },
      {
        property: 'og:description',
        content:
          '塗装検査工程のライン照明の接続部に切れ目が生じて不良を見逃しやすいなら、つなぎ目のない照明器具の導入で車体品質の確保をサポートします。'
      },
      {
        property: 'og:url',
        content: 'https://luci.co.jp/genbashiko/case/case01'
      },
      { property: 'og:type', content: 'article' }
    ]
  }
}
</script>
